import React, { Component } from 'react';
import './App.css';
import BuyNow from "./BuyNow";
import Grid from '@material-ui/core/Grid';

class ShopifyBuyNow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFlushed: false
		};
	}

    componentDidMount() {
		/*const script = document.createElement("script");
	    script.src = "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
	    script.async = true;
	    script.onload = () => this.scriptLoaded();
	    document.body.appendChild(script);*/

        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                this.ShopifyBuyInit();
            }
            else {
                this.loadScript();
            }
        }
        else {
            this.loadScript();
        }
    }

    loadScript() {
        var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        var script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = this.ShopifyBuyInit;
    }

    ShopifyBuyInit() {
        var client = window.ShopifyBuy.buildClient({
            domain: 'newfangle-llc.myshopify.com',
            storefrontAccessToken: 'a756723c50787179031f4335cd6feda4',
        });
        window.ShopifyBuy.UI.onReady(client).then(function (ui) {
			/* The NP-T */
			ui.createComponent('product', {
				id: '6747657076933',
				node: document.getElementById('product-component-np-t'),
				moneyFormat: '%24%7B%7Bamount%7D%7D',
				options: {
					"product": {
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "calc(25% - 20px)",
									"margin-left": "20px",
									"margin-bottom": "50px"
								}
							}
						},
						"buttonDestination": "modal",
						"contents": {
							"options": false,
							"price": false
						},
						"text": {
							"button": "View product"
						}
					},
					"productSet": {
						"styles": {
							"products": {
								"@media (min-width: 301px)": {
									"margin-left": "-20px"
								}
							}
						}
					},
					"modalProduct": {
						"contents": {
							"img": false,
							"imgWithCarousel": true,
							"button": false,
							"buttonWithQuantity": true
						},
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "100%",
									"margin-left": "0px",
									"margin-bottom": "0px"
								}
							}
						},
						"text": {
							"button": "Add to cart"
						}
					},
					"cart": {
						"text": {
							"total": "Subtotal",
							"button": "Checkout"
						}
					}
				},
			});

			/* Women's Racerback Tank */
			ui.createComponent('product', {
				id: '6747665596613',
				node: document.getElementById('product-component-womens-racerback-tank'),
				moneyFormat: '%24%7B%7Bamount%7D%7D',
				options: {
					"product": {
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "calc(25% - 20px)",
									"margin-left": "20px",
									"margin-bottom": "50px"
								}
							}
						},
						"buttonDestination": "modal",
						"contents": {
							"options": false,
							"price": false
						},
						"text": {
							"button": "View product"
						}
					},
					"productSet": {
						"styles": {
							"products": {
								"@media (min-width: 301px)": {
									"margin-left": "-20px"
								}
							}
						}
					},
					"modalProduct": {
						"contents": {
							"img": false,
							"imgWithCarousel": true,
							"button": false,
							"buttonWithQuantity": true
						},
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "100%",
									"margin-left": "0px",
									"margin-bottom": "0px"
								}
							}
						},
						"text": {
							"button": "Add to cart"
						}
					},
					"cart": {
						"text": {
							"total": "Subtotal",
							"button": "Checkout"
						}
					}
				},
			});

			/* Newfangle Press T-Shirt */
			ui.createComponent('product', {
				id: '6747662090437',
				node: document.getElementById('product-component-newfangle-press-t'),
				moneyFormat: '%24%7B%7Bamount%7D%7D',
				options: {
					"product": {
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "calc(25% - 20px)",
									"margin-left": "20px",
									"margin-bottom": "50px"
								}
							}
						},
						"buttonDestination": "modal",
						"contents": {
							"options": false,
							"price": false
						},
						"text": {
							"button": "View product"
						}
					},
					"productSet": {
						"styles": {
							"products": {
								"@media (min-width: 301px)": {
									"margin-left": "-20px"
								}
							}
						}
					},
					"modalProduct": {
						"contents": {
							"img": false,
							"imgWithCarousel": true,
							"button": false,
							"buttonWithQuantity": true
						},
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "100%",
									"margin-left": "0px",
									"margin-bottom": "0px"
								}
							}
						},
						"text": {
							"button": "Add to cart"
						}
					},
					"cart": {
						"text": {
							"total": "Subtotal",
							"button": "Checkout"
						}
					}
				},
			});

			/* Newfangle Press Glass */
			ui.createComponent('product', {
				id: '7824353886405',
				node: document.getElementById('product-component-newfangle-press-glass'),
				moneyFormat: '%24%7B%7Bamount%7D%7D',
				options: {
					"product": {
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "calc(25% - 20px)",
									"margin-left": "20px",
									"margin-bottom": "50px"
								}
							}
						},
						"buttonDestination": "modal",
						"contents": {
							"options": false,
							"price": false
						},
						"text": {
							"button": "View product"
						}
					},
					"productSet": {
						"styles": {
							"products": {
								"@media (min-width: 301px)": {
									"margin-left": "-20px"
								}
							}
						}
					},
					"modalProduct": {
						"contents": {
							"img": false,
							"imgWithCarousel": true,
							"button": false,
							"buttonWithQuantity": true
						},
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "100%",
									"margin-left": "0px",
									"margin-bottom": "0px"
								}
							}
						},
						"text": {
							"button": "Add to cart"
						}
					},
					"cart": {
						"text": {
							"total": "Subtotal",
							"button": "Checkout"
						}
					}
				},
			});

			/* The Ingenue - Hardback */
            ui.createComponent('product', {
                id: '7387601305797',
                node: document.getElementById('product-component-ingenue-hardback'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });

			/* The Ingenue - Paperback v2 */
            ui.createComponent('product', {
                id: '7387713732805',
                node: document.getElementById('product-component-ingenue-v2'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });

			/* Fortunate Son */
            ui.createComponent('product', {
                id: '7668109148357',
                node: document.getElementById('product-component-fortunate-son'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });

			/* Repercussions of Us */
            ui.createComponent('product', {
                id: '7668131594437',
                node: document.getElementById('product-component-repercussions'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });

			/* Lucky */
            ui.createComponent('product', {
                id: '7934131306693',
                node: document.getElementById('product-component-lucky'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });

			/* This Isnt The End */
            ui.createComponent('product', {
                id: '7934277812421',
                node: document.getElementById('product-component-thisisnttheend'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });

			/* The Living Episode 1 */
            ui.createComponent('product', {
                id: '7379001704645',
                node: document.getElementById('product-component-living001'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });

			/* Silent Wonderings (Pocket Book) */
			/*ui.createComponent('product', {
                id: '6538362421445',
                node: document.getElementById('product-component-silent-wonderings'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 301px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 301px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 301px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });*/

			/* Silent Wonderings (Paperback) */
			ui.createComponent('product', {
                id: '7357093085381',
                node: document.getElementById('product-component-silent-wonderings-paperback'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                            "product": {
                                "@media (min-width: 301px)": {
                                    "max-width": "calc(25% - 20px)",
                                    "margin-left": "20px",
                                    "margin-bottom": "50px"
                                }
                            }
                        },
                        "buttonDestination": "modal",
                        "contents": {
                            "options": false,
							"price": false
                        },
                        "text": {
                            "button": "View product"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 301px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 301px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "cart": {
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    }
                },
            });

			/* Electric Sappho (Pocket Book) [now unused] */
			/*ui.createComponent('product', {
				id: '6969940607173',
				node: document.getElementById('product-component-electric-sappho'),
				moneyFormat: '%24%7B%7Bamount%7D%7D',
				options: {
					"product": {
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "calc(25% - 20px)",
									"margin-left": "20px",
									"margin-bottom": "50px"
								}
							}
						},
						"buttonDestination": "modal",
						"contents": {
							"options": false,
							"price": false
						},
						"text": {
							"button": "View product"
						}
					},
					"productSet": {
						"styles": {
							"products": {
								"@media (min-width: 301px)": {
									"margin-left": "-20px"
								}
							}
						}
					},
					"modalProduct": {
						"contents": {
							"img": false,
							"imgWithCarousel": true,
							"button": false,
							"buttonWithQuantity": true
						},
						"styles": {
							"product": {
								"@media (min-width: 301px)": {
									"max-width": "100%",
									"margin-left": "0px",
									"margin-bottom": "0px"
								}
							}
						},
						"text": {
							"button": "Add to cart"
						}
					},
					"cart": {
						"text": {
							"total": "Subtotal",
							"button": "Checkout"
						}
					}
				},
			});*/
        });
    }

    scriptLoaded() {
    }

    render() {
		/* Example of how to create a "coming soon" item */
		let otherCmp = <BuyNow
			productTitle="The Ingénue (Paperback)"
			disabled={true}
			buttonTitle="Available Oct 31"
			imageSrc="https://cdn.shopify.com/s/files/1/0504/8706/6821/products/3d-product-image_550x825.png?v=1603375808"
		/>;

		/*
		<Grid item xs="12" sm="6" md="4" lg="3">
			<div id='product-component-ingenue-v2' style={{ paddingBottom: '12px' }}/>
		</Grid>
		*/

		/*

		<Grid item xs="12" sm="6" md="4" lg="3">
			<BuyNow
				style={{ display: 'none' }}
				productTitle="Silent Wonderings (Pocket Book)"
				disabled={true}
				buttonTitle="Out of print"
				imageSrc="https://cdn.shopify.com/s/files/1/0504/8706/6821/products/silent-wonderings-3d-physical_350x350.png?v=1614260156"
			/>
		</Grid>
		<Grid item xs="12" sm="6" md="4" lg="3">
			<BuyNow
				style={{ display: 'none' }}
				productTitle="Electric Sappho (Pocket Book)"
				disabled={true}
				buttonTitle="Out of print"
				imageSrc="https://cdn.shopify.com/s/files/1/0504/8706/6821/products/electric-sappho-physical.png?v=1638716871"
			/>
		</Grid>


		*/


    	return (
			<div style={{ marginBottom: "100px" }}>
			<Grid container direction="row" justify="center" alignItems="flex-end">
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-thisisnttheend' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-lucky' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-repercussions' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-fortunate-son' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-ingenue-v2' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-ingenue-hardback' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<BuyNow
						productTitle="The Ingénue (Kindle Edition)"
						disabled={false}
						buttonTitle="View product"
						buttonLink="https://www.amazon.com/gp/r.html?C=2Z24ENUUAEWUK&K=3AIIK8M6AH4S9&M=urn:rtn:msg:202010230347527607674f04834c2c93a2f9494730p0na&R=HR8XCNWZMRAQ&T=C&U=http%3A%2F%2Fwww.amazon.com%2Fdp%2FB08LP653NR%3Fref_%3Dpe_3052080_276849420&H=AWK8AIUCNMAPPWF9OGQPD08LMUIA&ref_=pe_3052080_276849420"
						imageSrc="https://cdn.shopify.com/s/files/1/0504/8706/6821/products/3d-kindle-cover_550x825.jpg?v=1603375808"
					/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<BuyNow
						productTitle="The Ingénue (Audiobook)"
						disabled={false}
						buttonTitle="View product"
						buttonLink="https://www.audible.com/pd/B09N4QZH3W"
						imageSrc="https://cdn.shopify.com/s/files/1/0504/8706/6821/products/61af6d2ade907d00171e376f-c9e7545649424a6-2021-12-0714_19_15.225221.png?v=1638886874"
					/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-living001' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-newfangle-press-glass' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-np-t' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-newfangle-press-t' style={{ paddingBottom: '12px' }}/>
				</Grid>
				<Grid item xs="12" sm="6" md="4" lg="3">
					<div id='product-component-womens-racerback-tank' style={{ paddingBottom: '12px' }}/>
				</Grid>
			</Grid>
			</div>
    	);
    }
}

/*const mapStateToProps = (state, ownProps) => {
	return {
		active: ownProps.blogs === state.blogs
	}
}

export default connect(mapStateToProps)(Shop);*/

export default ShopifyBuyNow;
