import React, { Component } from 'react';
import './App.css';
import { Container, Row, Col } from 'reactstrap';
import SubmissionForm from "./forms/SubmissionForm";

class Submissions extends Component {
	constructor(props) {
		super(props);
		this.state = {
            status: 'open'
		};
	}

    render() {
		let body = 'closed' === this.state.status ?
			<Row>
				<Col xs="12" sm="10">
					<div className="site-text">
						Check back soon for more information.
					</div>
				</Col>
				<Col sm="2">
				</Col>
			</Row>
		 :
			<Row>
				<Col xs="12" sm="10">
					<div className="site-text">
						We consider manuscripts which are:
						<ul>
							<li>Completed, original, and unpublished or self-published</li>
							<li>Fiction novels or novellas, non-fiction novels or novellas, or graphic novels</li>
						</ul>
					</div>
					<div className="site-text">
						We do <span style={{ 'fontWeight': 'bold' }}>not</span> consider:
						<ul>
							<li>Incomplete manuscripts</li>
							<li>Screenplays or children's books</li>
							<li>Poetry</li>
						</ul>
					</div>
					<div className="site-text">
						To submit a query letter for your completed manuscript, fill out the following form, and attach the first five pages of your manuscript as a PDF or DOC file.
					</div>
				</Col>
				<Col sm="2">
				</Col>
				<Col xs="12" sm="12">
					<SubmissionForm/>
				</Col>
			</Row>;

		return (
            <Container style={{ marginBottom: "150px" }}>
                <Row>
                    <Col xs="12" sm="10">
                    	<div className="site-text">
							Newfangle Press is currently <img src={require('./images/' + this.state.status + '.png')} width="64" alt={this.state.status}/> for submissions.
						</div>
                    </Col>
                    <Col sm="2">
                    </Col>
                </Row>
				{body}
            </Container>
		);
	}
}

/*const mapStateToProps = (state, ownProps) => {
	return {
		active: ownProps.blogs === state.blogs
	}
}

export default connect(mapStateToProps)(Submissions);*/

export default Submissions;
